<template>
  <div class="zh" id="zh">
    <header>
      <header-view />
      <!-- 轮播图 -->
      <div class="swiper-box">
        <img :src="$img + data.background_image" alt="" v-if="data.background_image" />
        <img src="@/assets/edp/images/layout/default.png" alt="" v-else />
      </div>
      <!-- 面包栏 -->
      <div class="bread-bar">
        <!-- pc端导航栏 -->
        <div class="pc">
          <div class="auxiliary-navigation">
            <router-link :to="handlerRouteParams(data.viceList)">{{
              data.viceList.title_en
            }}</router-link>
            <ul class="navigation" v-show="data.viceList.children">
              <li v-for="(item, i) in data.viceList.children" class="list" :key="i">
                <router-link :to="handlerRouteParams(item)" active-class="active">{{ item.title_en }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <!-- 面包屑 -->
        <div class="crumbs">
          <!-- separator分隔符 -->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="item in data.crumbs" :to="{ name: item.vue_url }" :key="item.id">{{ item.title_en
            }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 移动端标题栏 -->
        <div class="mobile">
          <div class="mobile-nav ">
            <span>{{ data.viceList.title_en }}</span>
          </div>
        </div>

      </div>
      <sign-view/>
    </header>
    <main>
      <div class="box">
        <content-title :title="data.title_name" v-if="!$route.meta.isTitle" />
        <div v-if="toggle">
          <router-view #Component>
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
    </main>

    <footer>
      <footer-view />
    </footer>
  </div>
</template>

<script setup>
import headerView from "@/components/edp/ZH/header.vue";
import contentTitle from "./component/contentTitle.vue";
import footerView from "@/components/edp/ZH/footerView.vue";
import signView from "@/components/edp/ZH/signView.vue";
import { onMounted, reactive, watch, ref, nextTick } from "vue";
import { useRoute } from "vue-router";
// api
import { apiViceNavigation, apiNavigationBar } from "@/api/layout.js";
// 点击切换导航栏

let data = reactive({
  viceList: {}, // 副导航
  crumbs: [], // 面包屑
  title_name: "", //副导航标题
  background_image: "",
});

// 获取url中的参数
let $route = useRoute();
let params = $route.params;
// 获取面包屑
const getNavList = async () => {
  try {
    let { code, data: reslut } = await apiViceNavigation({
      id: params.navId || params.detailsId,
    });

    if (code == 200) {
      data.crumbs = reslut;
    }
  } catch (e) {
    console.error(e);
  }
};
// 获取副导航
const handlerSubNav = async () => {
  try {
    let { data: result } = await apiNavigationBar({
      id: params.navId || params.detailsId,
    });
    data.viceList = result.data[0];
    data.background_image = data.viceList.url ? data.viceList.url : "";
    data.title_name = result.type_name;
  } catch (e) {
    console.error(e);
  }
};

// 获取api
onMounted(() => {
  getNavList();
  handlerSubNav();
});

let toggle = ref(true);
watch(
  () => $route.path,
  (newValue) => {
    if (newValue.includes("/edps")) {
      params = $route.params;
      getNavList();
      handlerSubNav();
      toggle.value = false;
      nextTick(() => {
        toggle.value = true;
      });
    }
  }
);
// 处理路由参数
const handlerRouteParams = (value) => {
  let tuple = ["user", "detile"];
  return {
    name: value.vue_url,
    params: {
      detailsId: !tuple.includes(value.vue_url) ? value.id : value.article_id,
      navId: value.id,
    },
  };
};
</script>

<style scoped lang="scss">
@import "~@/style/reset.scss";
@import "~@/style/varclass.scss";
@media screen and (min-width: $media) {
  .zh {
    width: 15rem;

    header {
      width: 100%;

      .swiper-box {
        height: 2.9297rem;
        width: 100%;
        background-color: #fff;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .bread-bar {
        width: 100%;
        height: 0.7813rem;
        background: url(~@/assets/edp/images/layout/navigation.png) no-repeat;
        background-size: 100% 100%;
        padding: 0px 1.2969rem;
        display: flex;
        justify-content: space-between;

        .auxiliary-navigation {
          width: 2.9844rem;
          height: 0.7813rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #de3a00;
          position: relative;

          >a {
            font-size: 0.2969rem;
            font-weight: 600;
            color: #ffffff;
            line-height: 0.2969rem;
          }

          .navigation {
            width: 2.9844rem;
            position: absolute;
            top: 0.7813rem;
            left: 0px;
            background-color: rgba(0, 0, 0, 0.2);
            padding: 0px 0px 0.3672rem 0.3516rem;

            >.list {
              display: flex;
              align-items: center;
              padding-left: 0.3516rem;
              padding: 0.25rem 0px;
              border-bottom: 0.0078rem dashed #9a9a9a;

              >a {
                margin-left: 0.125rem;
                width: 0.75rem;
                font-size: 0.1875rem;
                font-weight: 500;
                color: $hover-color;
                cursor: pointer;
              }
            }

            .active {
              position: relative;

              &::before {
                position: absolute;
                top: 50%;
                left: -0.125rem;
                transform: translateY(-50%);

                content: "";
                display: inline-block;
                width: 0.0391rem;
                height: 0.1641rem;
                background-color: $hover-color;
              }
            }
          }
        }

        // 面包屑
        .crumbs {
          display: flex;
          align-items: center;

          &:deep(.el-breadcrumb__item) {
            .el-breadcrumb__inner {
              color: #fff;
              font-size: 0.1719rem;
              font-weight: 500;
              cursor: pointer;
            }

            .el-breadcrumb__separator {
              color: #fff;
              font-size: 0.1719rem;
              font-weight: 500;
            }
          }
        }
      }
    }

    main {
      width: 100%;
      min-height: 7.5rem;
      padding: 0px 0px 0px 4.8828rem;
      background-color: #fff;

      >.box {
        width: 8.8203rem;
        height: 100%;

        .fade-enter,
        .fade-leave-to {
          transform: scale(0);
          opacity: 0;
        }

        .fade-enter-active,
        .fade-leave-active {
          transition: all 0.3s;
        }

        .fade-enter-to,
        .fade-leave {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: $media) {
  .zh {
    header {
      width: 100%;

      .swiper-box {
        height: 4.18rem;
        width: 100%;
        background-color: #fff;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .bread-bar {
        width: 100%;
        height: 8.5333vw;
        background: #e5e5e5;
        padding-left: 4.5333vw;
        display: flex;
        justify-content: space-between;

        // 面包屑
        .crumbs {
          display: flex;
          align-items: center;

          &:deep(.el-breadcrumb__item) {
            .el-breadcrumb__inner {
              color: #484848;
              font-size: 2.6667vw;
              font-weight: 500;
              cursor: pointer;
            }

            .el-breadcrumb__separator {
              color: #484848;
              font-size: 2.6667vw;
              font-weight: 500;
              padding: 0px 1.3333vw;
            }
          }

          &:deep(.el-breadcrumb__item:last-child) {
            .el-breadcrumb__inner {
              color: #a6291a;
            }
          }
        }

        // 标题
        .mobile-nav {
          width: 26.9333vw;
          height: 8.5333vw;
          background: #de3a00;
          border-radius: 0px 0px 0px 0px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          >span {
            font-size: 3.2vw;
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
    }

    main {
      width: 100%;
      min-height: 60vw;
      padding: 0px 2.6667vw;

      >.box {
        width: 100%;
        height: 100%;

        .fade-enter,
        .fade-leave-to {
          transform: scale(0);
          opacity: 0;
        }

        .fade-enter-active,
        .fade-leave-active {
          transition: all 0.3s;
        }

        .fade-enter-to,
        .fade-leave {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }
}
</style>
